<template>
  <main>
    <MainMenu/>

    <div class="frame-modal-wrapper quick-cart" id="quick_cart">
      <div class="modal-wrapper-holder">

        <div class="frame-modal-header">
          <div class="name h2">Ваша корзина</div>
          <a href="javascript:void(0);" class="close-cart" @click="closeModal">
            <img src="/images/icons/close.svg" alt="">
          </a>
        </div>
        <div class="frame-modal-body">
          <ul class="frame-modal-list">

            <li class="cart-item " v-for="(order, index) in busked" :key="index">
              <div class="item-holder">
                <a class="remove">
                  <img src="/images/icons/delete.svg" alt="" @click.prevent="delBasked(order,index)">
                </a>
                <div class="item-info">
                  <div class="image bg" :style="{backgroundImage: 'url(/'+order.image+')'}"></div>
                  <div class="right-side">
                    <div class="name">{{order.name}}</div>
                    <div class="info-labels">
                      <!--                      <span class="info-label" v-if="order.count">Кількість: <span>{{order.count}}</span></span>-->
                      <span class="info-label" v-if="order.sizeSelect">Розмір: <span>{{order.sizeSelect.name}}</span></span>
                      <span class="info-label" v-if="order.sizeSelect">Вага: <span>{{order.sizeSelect.weight}}</span></span>
                      <span class="info-label" v-else>Вага: <span>{{order.weight}}</span></span>
                    </div>
                    <div class="additional">
                      <div class="additional-line" v-for="(add, addindex) in order.additionSelect" :key="addindex">
                        <div class="add-name">{{add.name}}:</div>
                        <name class="value">{{add.price}} грн</name>
                      </div>

                    </div>
                    <div class="subtotal">{{order.count}} x {{itemPrice(order)}} грн</div>
                  </div>
                </div>
              </div>
            </li>

          </ul>
        </div>
        <div class="frame-back-link">
          <a href="javascript:void(0)" class="back-link" @click="closeModal">
            <img src="/images/icons/left.svg"> <span class="text">Продовжити покупки</span>
          </a>
        </div>
        <div class="frame-modal-bottom">
          <div class="total">
            <span class="total-name">Всього:</span>
            <span class="total-value">{{totalPrice}} <small>грн</small></span>
          </div>
          <button class="default-btn" @click="openOrder">
            <span class="btn-text">оформити</span>
          </button>
        </div>
      </div>
    </div>
    <div class="frame-modal-wrapper-bg"></div>
    <div class="fixed-holder-btn">
      <a href="javascript:void(0)" @click="orderStore" class="fixed-btn">
        <span class="btn-text">Замовити магазин</span>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.3447 1.92496C11.5752 2.02311 11.7248 2.24946 11.7248 2.50002V8.33184H14.9998C15.2221 8.33184 15.4276 8.44987 15.5396 8.64183C15.6517 8.83379 15.6533 9.0708 15.544 9.2643L15.1339 9.99001C13.5788 12.7422 11.5956 15.2294 9.25865 17.3582L8.59572 17.9621C8.41276 18.1287 8.14867 18.1717 7.92228 18.0717C7.69589 17.9717 7.54984 17.7475 7.54984 17.5V11.7176H4.1665C3.82133 11.7176 3.5415 11.4378 3.5415 11.0926C3.5415 10.9808 3.57086 10.8759 3.62228 10.7851C5.08794 8.07555 6.96442 5.60932 9.18502 3.47414L10.6666 2.04949C10.8473 1.87583 11.1141 1.82681 11.3447 1.92496ZM5.23698 10.4676H8.17484C8.52002 10.4676 8.79984 10.7475 8.79984 11.0926V16.0788C10.8153 14.1741 12.5429 11.9856 13.9277 9.58184H11.0998C10.7547 9.58184 10.4748 9.30202 10.4748 8.95684V3.96803L10.0514 4.37518C8.17576 6.17869 6.55635 8.2287 5.23698 10.4676Z"
                fill="black"/>
        </svg>
      </a>
    </div>

    <div class="quick-card-sticker" id="quick_card_sticker">
      <div class="quick-card-sticker-holder">
        <a href="javascript:void(0);">
          <span class="notification">{{busked.length}}</span>
          <img src="/images/icons/bag.svg">
        </a>
      </div>
    </div>

    <MainHeader/>

    <router-view/>
  </main>
</template>

<script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  // import $ from 'jquery';

  // import Category from './Category';
  import MainHeader from './MainHeader';
  import MainMenu from './MainMenu';
  import axios from 'axios'

  export default {
    name      : 'Home',
    components: {MainHeader, MainMenu},
    computed  : {
      busked() {
        return this.$store.state.busked
      },
      totalPrice() {
        return this.busked.reduce((total, value) => total + this.itemPrice(value), 0)
      }
    },
    methods   : {
      orderStore(){
        if (window.Telegram) {
          axios.post('/api/lead', window.Telegram ? window.Telegram.WebApp.initData : '', {
            headers: {
              initData: window.Telegram ? window.Telegram.WebApp.initData : ''
            }
          }).then(() => {
            document.location.replace('https://t.me/UXLoveBot');
          });
        } else {
          document.location.replace('https://t.me/UXLoveBot?start=invoice_from_demo_food');
        }

      },

      itemPrice(item) {
        return (item.price + this.size_price(item) + this.addition_price(item)) * item.count
      },
      size_price(item) {
        return item.sizeSelect ? item.sizeSelect.price : 0
      },
      addition_price(item) {
        return item.additionSelect.reduce((c, value) => c + value.price, 0)
      },
      makeFilter() {
        window.$('.frame-modal-wrapper-bg').removeClass('is-visible');
        window.$('body').removeClass('no-scroll');

        window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
        window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');
      },
      delBasked(item, index) {
        this.$store.commit('delBasked', index)
      },
      closeModal() {
        (function ($) {
          'use strict';
          $('.frame-modal-wrapper-bg').removeClass('is-visible');
          $('.overlay-menu').removeClass('active');
          $('body').removeClass('no-scroll');

          $('#quick_cart').removeClass('opened');

        })(window.$);
      },
      openOrder() {
        this.$router.push({name: 'Order'});

        window.$('.frame-modal-wrapper-bg').removeClass('is-visible');
        window.$('body').removeClass('no-scroll');

        window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
        window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');
      }
    },
    beforeUnmount() {
      // var html5Slider = document.getElementById('slider-price');
      //
      // html5Slider.noUiSlider.destroy()
      // window.topGalery?.destroy();
      // console.log('clear galery')
    },
    mounted() {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      // document.getElementById('subtitle').innerText = navigator.userAgent;
      if (navigator.userAgent.indexOf('iPhone') > -1) {
        window.Telegram.WebApp.onEvent('viewportChanged', window.Telegram.WebApp.expand)
      }

    }

  }
</script>
