import { createRouter, createWebHistory } from 'vue-router'
import About from '../views/About';
import Category from '../views/Category';
import Contact from '../views/Contact';
import Delivery from '../views/Delivery';
import Home from '../views/Home.vue'
import Item from '../views/Item.vue'
import Order from '../views/Order.vue'

const routes = [
  {
    path     : '/',
    name     : 'Home',
    component: Home,
    children : [
      {
        path     : '/:category?',
        name     : 'Category',
        props    : true,
        component: Category,
        alias    : ''
      },
      {
        path     : '/contact',
        name     : 'Contact',
        component: Contact,
      },
      {
        path     : '/delivery',
        name     : 'Delivery',
        component: Delivery,
      },
      {
        path     : '/about',
        name     : 'About',
        component: About,
      },
      {
        path     : '/:category/item/:id?',
        name     : 'Item',
        props    : true,
        component: Item
      },
      {
        path     : '/order',
        name     : 'Order',
        props    : true,
        component: Order
      },
      
    ]
  },
  

];

const router = createRouter({
                              history: createWebHistory(process.env.BASE_URL),
                              routes,
                              scrollBehavior: (to, from, savedPosition) => {
                                if (savedPosition) {
                                  return savedPosition;
                                } else if (to.hash) {
                                  return {
                                    selector: to.hash
                                  };
                                } else {
                                  return { x: 0, y: 0 };
                                }
                              }
                            });

let last_path = localStorage.getItem('last_path');

function initRecord() {
  router.beforeEach((to) => {
    // console.log(to);
    (function ($) {
      'use strict';
      $('.frame-modal-wrapper-bg').removeClass('is-visible');
      $('.overlay-menu').removeClass('active');
      $('body').removeClass('no-scroll');

      $('#quick_cart').removeClass('opened');

    })(window.$);
    window.scrollTo(0, 0);

    localStorage.setItem('last_path', to.path);
    return true
  });

  let lastTs = 0;
  window.addEventListener('scroll', (event) => {
    if (lastTs + 100 < event.timeStamp) {

      lastTs = event.timeStamp;
      localStorage.setItem('save_y', window.pageYOffset);
    }
  });
}

router.isReady().then(() => {
  if (last_path && window.location.pathname !== last_path) {
    router.push(last_path);
  }
  try {
    let lastSaveY = parseInt(localStorage.getItem('save_y'), 10);
    setTimeout(() => {window.scrollTo(0, lastSaveY)}, 100)
  } catch (e) {
    console.log(e)
  }
  initRecord()

});

export default router
