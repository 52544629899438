<template>
  <div class="product-top-panel">
    <router-link :to="{name: 'Category', params:{ category: category }}" class="back-link">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.5 10L7.14645 9.64645L6.79289 10L7.14645 10.3536L7.5 10ZM12.1464 4.64645L7.14645 9.64645L7.85355 10.3536L12.8536 5.35355L12.1464 4.64645ZM7.14645 10.3536L12.1464 15.3536L12.8536 14.6464L7.85355 9.64645L7.14645 10.3536Z"
            fill="black"/>
      </svg>
      <span class="text">Назад в категорію</span>
    </router-link>
  </div>

  <div class="product-images">
    <ul>
      <li v-for="(image,index) in item.slider" :key="index">
        <a :href="'/'+image" data-fancybox="zoom" class="image bg" :style="{backgroundImage: 'url(/'+image+')'}"></a>
      </li>
    </ul>

  </div>

  <div class="product-wrapper wrapper">
    <div class="availability in-stock">
      <span>У наявності </span>
    </div>
    <div class="name h2">{{item.name}}</div>
    <div class="product-info-options">
      <div class="size-holder" v-if="item.size">
        <div class="custom-label">Розмір:</div>
        <div class="size-radio-group">
          <div class="size-radio" v-for="(size,index) in item.size" :key="index">
            <input :id="'size'+index" type="radio" name="size-radio1" v-model="sizeSelected" :value="size">
            <label :for="'size'+index">{{size.name}}</label>
          </div>
        </div>
      </div>
      <div class="info-labels">
        <span class="info-label">Вага:  <span>{{weight}}</span></span>
      </div>
    </div>
    <div class="additional-group" v-if="addition.length">
      <div class="custom-label">Додатково:</div>
      <div class="additional-list">
        <div class="additional-checkbox" v-for="(item, index) in addition_show" :key="index">
          <input :id="'add'+index" type="checkbox" :value="item" v-model="order.additionSelect">
          <label :for="'add'+index">
            <span class="image" :style="{backgroundImage: 'url(/'+item.image+')'}"></span>
            <span class="text">
									<span class="ingredient-name">{{item.name}}</span>
									<span class="ingredient-price">+ {{item.price}} грн</span>
								</span>
            <span class="ingredient-size">{{item.weight}}</span>
          </label>
        </div>
      </div>
      <div class="additional-more" v-if="addition_more.length">
        <div class="collapse " id="collapseAdditional">
          <div class="additional-list">
            <div class="additional-checkbox" v-for="(item, index) in addition_more" :key="index">
              <input :id="'add-m'+index" type="checkbox" :value="item" v-model="order.additionSelect">
              <label :for="'add-m'+index">
                <span class="image" :style="{backgroundImage: 'url(/'+item.image+')'}"></span>
                <span class="text">
									<span class="ingredient-name">{{item.name}}</span>
									<span class="ingredient-price">+ {{item.price}} грн</span>
								</span>
                <span class="ingredient-size">{{item.weight}}</span>
              </label>
            </div>
          </div>
        </div>
        <a class="collapse-link default-link" data-toggle="collapse" href="#collapseAdditional" role="button" aria-expanded="false"
           aria-controls="collapseAdditional">
          <span class="text">Усі додатки</span>
        </a>

      </div>
    </div>
    <div class="product-buy-options">
      <div class="price-box">
        <div class="price-element">₴ {{price}}</div>
      </div>
      <div class="nt-spinner">
        <button class=" nt-btn btn_less icons icon-minus" type="button" @click="addCount( -1)"></button>
        <input type="text" class="form-control " v-model="order.count">
        <button class=" nt-btn btn_more icons icon-plus" type="button" @click="addCount(+1)"></button>
      </div>

      <button class="default-btn " @click="buy">
        <span class="btn-text">Замовити</span>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M6.25 7.08325L6.25 6.66658C6.25 4.59552 7.92893 2.91658 10 2.91658V2.91658C12.0711 2.91658 13.75 4.59551 13.75 6.66658L13.75 7.08325"
              stroke="white" stroke-linecap="round"/>
          <path d="M12.9165 11.2499V9.58325" stroke="white" stroke-linecap="round"/>
          <path d="M7.0835 11.2499V9.58325" stroke="white" stroke-linecap="round"/>
          <path
              d="M4.5835 10.6833C4.5835 8.9862 4.5835 8.13767 5.1107 7.61046C5.63791 7.08325 6.48644 7.08325 8.1835 7.08325H11.8168C13.5139 7.08325 14.3624 7.08325 14.8896 7.61046C15.4168 8.13767 15.4168 8.9862 15.4168 10.6833V11.6666C15.4168 13.9324 15.4168 15.0653 14.8362 15.8572C14.6552 16.104 14.4376 16.3216 14.1908 16.5026C13.3988 17.0833 12.2659 17.0833 10.0002 17.0833V17.0833C7.73438 17.0833 6.6015 17.0833 5.80957 16.5026C5.56277 16.3216 5.34512 16.104 5.16416 15.8572C4.5835 15.0653 4.5835 13.9324 4.5835 11.6666V10.6833Z"
              stroke="white"/>
        </svg>
      </button>
    </div>
    <div class="product-info" v-if="item.description">
      <a class="collapse-link h2" data-toggle="collapse" href="#collapseInfo" role="button" aria-expanded="true"
         aria-controls="collapseInfo">
        Про продукт:
      </a>
      <div class="collapse show" id="collapseInfo">
        <div class="text">
          Склад: {{item.description}}.
        </div>
      </div>
    </div>

  </div>
  <div class="related-products">
    <div class="name h2">З цим часто купують:</div>
    <ul class="catalog-grid">
      <li class="catalog-item" v-for="(item,index) in buy_more" :key="index">
        <div class="item-holder">
          <div class="value-info">
            <span class="name">Об'єм:</span>
            <span class="value">{{item.weight}}</span>
          </div>
          <div class="options">
            <div class="price-box">
              <div class="price-element">₴ {{item.price}}</div>
            </div>
            <div class="right-options">
              <button class="default-btn btn-icon light-btn quick_cart_trigger ">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M6.25 7.08325L6.25 6.66658C6.25 4.59552 7.92893 2.91658 10 2.91658V2.91658C12.0711 2.91658 13.75 4.59551 13.75 6.66658L13.75 7.08325"
                      stroke="white" stroke-linecap="round"/>
                  <path d="M12.9165 11.2499V9.58325" stroke="white" stroke-linecap="round"/>
                  <path d="M7.0835 11.2499V9.58325" stroke="white" stroke-linecap="round"/>
                  <path
                      d="M4.5835 10.6833C4.5835 8.9862 4.5835 8.13767 5.1107 7.61046C5.63791 7.08325 6.48644 7.08325 8.1835 7.08325H11.8168C13.5139 7.08325 14.3624 7.08325 14.8896 7.61046C15.4168 8.13767 15.4168 8.9862 15.4168 10.6833V11.6666C15.4168 13.9324 15.4168 15.0653 14.8362 15.8572C14.6552 16.104 14.4376 16.3216 14.1908 16.5026C13.3988 17.0833 12.2659 17.0833 10.0002 17.0833V17.0833C7.73438 17.0833 6.6015 17.0833 5.80957 16.5026C5.56277 16.3216 5.34512 16.104 5.16416 15.8572C4.5835 15.0653 4.5835 13.9324 4.5835 11.6666V10.6833Z"
                      stroke="white"/>
                </svg>
              </button>
            </div>
          </div>
          <router-link :to="{name: 'Item', params:{category: getCategoryFromItem(item), id: item.id}}" class="image bg"
                       :style="{backgroundImage: 'url(/'+item.image+')'}">
            <div class="item-content">
              <div class="product-name">{{item.name}}</div>
            </div>
          </router-link>

        </div>
      </li>
    </ul>
  </div>

</template>

<script>
  export default {
    name      : 'Item',
    props     : ['id', 'category'],
    components: {},
    data() {
      return {
        order          : {
          sizeSelect    : undefined,
          count         : 1,
          additionSelect: []
        },
        sizeSelectIndex: 0
      }
    },
    watch     : {
      id() {
        window.scrollTo(0, 0);
        // this.order.size = this.item.sizes[0];
      }
    },
    computed  : {
      categories() {
        return this.$store.state.category
      },
      weight() {
        return this.sizeSelected ? this.sizeSelected.weight : this.item.weight
      },
      item() {
        if (this.id) {
          return this.$store.state.items[this.id];
        }
        return undefined
      },
      price() {
        return (this.item.price + this.size_price + this.addition_price) * this.order.count
      },
      size_price() {
        return this.item.size ? this.sizeSelected.price : 0
      },
      addition_price() {
        return this.order.additionSelect.reduce((c, value) => c + value.price, 0)
      },
      sizeSelected: {
        get() {
          return this.item.size ? this.order.sizeSelect ? this.order.sizeSelect : this.item.size[0] : undefined
        },
        set(value) {
          this.order.sizeSelect = value
        }
      },
      // sizeSelected() {
      //   return this.item.size ? this.order.sizeSelect ? this.item.size[0] : this.order.sizeSelect : undefined
      // },
      samples() {
        return this.$store.state.items.map((i, index) => {
          i.id = index;
          return i
        }).filter(i => i.category === this.item.category)
      },
      basked() {
        return this.$store.state.busked
      },
      buy_more() {
        return this.$store.state.buyMore.filter(i => i.category === this.item.category).map(i => {
          return i.list.map(id => this.$store.state.items[id])
        }).reduce((x, value) => x.concat(...value), [])
      },
      addition() {
        return this.$store.state.addition.filter(i => i.category === this.item.category).map((i, index) => {
          i.id = index;
          return i
        })
      },
      addition_show() {
        return this.addition.filter(i => i).splice(0, 4)
      },
      addition_more() {
        return this.addition.filter(i => i).splice(4)
      },

      baskedCount() {
        return this.$store.state.busked.length
      }

    },
    methods   : {
      getCategoryFromItem(item) {
        let found = this.categories.find(i => i.name === item.category);
        return found ? found.link : ''
      },
      addCount(i) {
        if (i + this.order.count) {
          this.order.count += i
        }
      },
      addItemCount(item, i) {
        if (i + item.count) {
          item.count += i
        }
      },
      buy() {
        let idx = -1;
        if (this.sizeSelected) {
          idx = this.basked.findIndex(i => i.id === this.item.id && i.sizeSelect.name === this.sizeSelected.name)
        } else {
          idx = this.basked.findIndex(i => i.id === this.item.id)
        }
        if (idx > -1) {
          // this.basked[idx].count += this.order.count;
        } else {
          this.$store.commit(
            'addToBasked',
            JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item, {sizeSelect: this.sizeSelected})))
          );
        }
        window.$('body').addClass('no-scroll');
        window.$('#quick_cart').addClass('opened');
        window.$('.frame-modal-wrapper-bg').addClass('is-visible');
      }

    },
    mounted() {
      window.scrollTo(0, 0);
      //  product-images
      window.$('.product-images a').fancybox();
      // this.order.size = this.item.sizes[0];
      // window.$('.quick_cart_trigger').on('click', function () {
      //   window.$('body').addClass('no-scroll');
      //   window.$('#quick_cart').addClass('opened');
      //   window.$('.frame-modal-wrapper-bg').addClass('is-visible');
      //   return false;
      // });

      window.$('.frame-modal-wrapper-bg').on('click', function () {
        window.$(this).removeClass('is-visible');
        window.$('body').removeClass('no-scroll');

        window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
        window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');
        return false;
      });
    }
  }
</script>