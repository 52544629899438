import { createStore } from 'vuex'

export default createStore({
                             state    : {
                               items:
                                 [
                                   {
                                     category   : 'Бургери',
                                     price      : 120,
                                     name       : 'Бургер “Chicago”',
                                     description: 'дві котлети з телятини, подвійний сир чеддер, солений огірок, цибуля ріпчаста, листя салату',
                                     isNew      : true,
                                     isSale     : false,
                                     weight     : '320 г',
                                     image      : 'images/burger/2.jpg',
                                     slider     : [
                                       'images/burger/2.jpg',
                                       'images/burger/2.jpg',
                                       'images/burger/2.jpg'
                                     ],
                                     tags       : ['bbq', 'new',]
                                   },
                                   {
                                     category   : 'Бургери',
                                     price      : 180,
                                     name       : 'Бургер + картопля фрі',
                                     description: 'Бургер - дві котлети з телятини, подвійний сир чеддер, солений огірок, цибуля ріпчаста, листя салату',
                                     isNew      : false,
                                     isSale     : true,
                                     weight     : '510 г',
                                     image      : 'images/burger/3.jpg',
                                     slider     : [
                                       'images/burger/3.jpg',
                                       'images/burger/3.jpg',
                                       'images/burger/3.jpg'
                                     ],
                                     tags       : [ 'vegetarian',  'onion', 'cheese']
                                   },
                                   {
                                     category   : 'Бургери',
                                     price      : 180,
                                     name       : 'Бургер “King”',
                                     description: 'Бургер - дві котлети з телятини, подвійний сир чеддер, солений огірок, цибуля ріпчаста, листя салату',
                                     isNew      : false,
                                     isSale     : false,
                                     weight     : '450 г',
                                     image      : 'images/burger/1.jpg',
                                     slider     : [
                                       'images/burger/1.jpg',
                                       'images/burger/1.jpg',
                                       'images/burger/1.jpg'
                                     ],
                                     tags       : ['bbq', 'backon', 'cheese']
                                   },
                                   {
                                     category   : 'Бургери',
                                     price      : 220,
                                     name       : 'Бургер “Black Burger”',
                                     description: 'Бургер - дві котлети з телятини, подвійний сир чеддер, солений огірок, цибуля ріпчаста, листя салату',
                                     isNew      : false,
                                     isSale     : false,
                                     weight     : '380 г',
                                     image      : 'images/burger/4.jpg',
                                     slider     : [
                                       'images/burger/4.jpg',
                                       'images/burger/4.jpg',
                                       'images/burger/4.jpg'
                                     ],
                                     tags       : ['bbq', 'onion', 'cheese']
                                   },
                                   {
                                     category   : 'Бургери',
                                     price      : 380,
                                     name       : 'Бургер “Double Burger”',
                                     description: 'Бургер - дві котлети з телятини, подвійний сир чеддер, солений огірок, цибуля ріпчаста, листя салату',
                                     isNew      : false,
                                     isSale     : false,
                                     weight     : '650 г',
                                     image      : 'images/burger/5.jpg',
                                     slider     : [
                                       'images/burger/5.jpg',
                                       'images/burger/5.jpg',
                                       'images/burger/5.jpg'
                                     ],
                                     tags       : ['vegetarian', 'new', 'onion', 'backon', 'cheese']
                                   },
                                   {
                                     category   : 'Піца',
                                     price      : 258,
                                     name       : 'Піца “М\'ясний мікс”',
                                     description: 'ферментоване м\'ясо, моцарела, телятина маринована, пепероні, бекон, мисливські ковбаски, соу спайс',
                                     isNew      : true,
                                     isSale     : false,
                                     weight     : '560 г',
                                     image      : 'images/pizza/1.jpg',
                                     slider     : [
                                       'images/pizza/1.jpg',
                                       'images/pizza/1.jpg',
                                       'images/pizza/1.jpg'
                                     ],
                                     size       : [
                                       {name: '30 см', price: 0, weight: '560 г'},
                                       {name: '40 см', price: 10, weight: '710 г'}
                                     ],
                                     tags       : ['bbq', 'onion', 'backon', 'cheese']
                                   },
                                   {
                                     category   : 'Піца',
                                     price      : 450,
                                     name       : 'Піца “Маргарита”',
                                     description: 'ферментоване м\'ясо, моцарела, телятина маринована, пепероні, бекон, мисливські ковбаски, соу спайс',
                                     isNew      : false,
                                     isSale     : false,
                                     weight     : '510 г',
                                     image      : 'images/pizza/2.jpg',
                                     slider     : [
                                       'images/pizza/2.jpg',
                                       'images/pizza/2.jpg',
                                       'images/pizza/2.jpg'
                                     ],
                                     size       : [
                                       {name: '30 см', price: 0, weight: '510 г'},
                                       {name: '40 см', price: 10, weight: '590 г'}
                                     ],
                                     tags       : ['vegetarian', 'backon', 'cheese']
                                   },
                                   {
                                     category   : 'Піца',
                                     price      : 390,
                                     name       : 'Піца “Пепероні”',
                                     description: 'ферментоване м\'ясо, моцарела, телятина маринована, пепероні, бекон, мисливські ковбаски, соу спайс',
                                     isNew      : false,
                                     isSale     : false,
                                     weight     : '500 г',
                                     image      : 'images/pizza/3.jpg',
                                     slider     : [
                                       'images/pizza/3.jpg',
                                       'images/pizza/3.jpg',
                                       'images/pizza/3.jpg'
                                     ],
                                     size       : [
                                       {name: '30 см', price: 0, weight: '500 г'},
                                       {name: '40 см', price: 10, weight: '640 г'}
                                     ],
                                     tags       : [ 'vegetarian', 'cheese']
                                   },
                                   {
                                     category   : 'Суші / роли',
                                     price      : 999,
                                     name       : 'Суші-сет “Філадельфія”',
                                     description: 'філадельфія, рол сяке з сиром, філадельфія в кунжуті, суші лосось з сиром філадельфія',
                                     isNew      : false,
                                     isSale     : false,
                                     weight     : '1870 г',
                                     image      : 'images/sushi/2.jpg',
                                     slider     : [
                                       'images/sushi/2.jpg',
                                       'images/sushi/2.jpg',
                                       'images/sushi/2.jpg'
                                     ]
                                   },
                                   {
                                     category   : 'Суші / роли',
                                     price      : 179,
                                     name       : 'Рол футомакі з тар-таром із лосося',
                                     description: 'Лосось, норі, рис, кисло-солодкий соус',
                                     isNew      : false,
                                     isSale     : false,
                                     weight     : '280 г',
                                     image      : 'images/sushi/1.jpg',
                                     slider     : [
                                       'images/sushi/1.jpg',
                                       'images/sushi/1.jpg',
                                       'images/sushi/1.jpg'
                                     ]
                                   },
                                   {
                                     category   : 'Суші / роли',
                                     price      : 189,
                                     name       : 'Рол Ебі Сяке з чукою',
                                     description: 'Лосось, норі, рис, кисло-солодкий соус, сир філадельфія, чука, креветка тигрова, сухарі панко',
                                     isNew      : false,
                                     isSale     : false,
                                     weight     : '300 г',
                                     image      : 'images/sushi/3.jpg',
                                     slider     : [
                                       'images/sushi/3.jpg',
                                       'images/sushi/3.jpg',
                                       'images/sushi/3.jpg'
                                     ]
                                   },
                                   {
                                     category: 'Напої',
                                     price   : 55,
                                     name    : 'Coca-cola',
                                     isNew   : false,
                                     isSale  : false,
                                     weight  : '0.5 л',
                                     image   : 'images/drink/1.jpg',
                                     slider  : [
                                       'images/drink/1.jpg',
                                       'images/drink/1.jpg',
                                       'images/drink/1.jpg'
                                     ]
                                   },
                                   {
                                     category: 'Напої',
                                     price   : 55,
                                     name    : 'Лимонад',
                                     isNew   : false,
                                     isSale  : false,
                                     weight  : '0.5 л',
                                     image   : 'images/drink/3.jpg',
                                     slider  : [
                                       'images/drink/3.jpg',
                                       'images/drink/3.jpg',
                                       'images/drink/3.jpg'
                                     ]
                                   },
                                   {
                                     category: 'Напої',
                                     price   : 55,
                                     name    : 'Fanta',
                                     isNew   : false,
                                     isSale  : false,
                                     weight  : '0.5 л',
                                     image   : 'images/drink/2.jpg',
                                     slider  : [
                                       'images/drink/2.jpg',
                                       'images/drink/2.jpg',
                                       'images/drink/2.jpg'
                                     ]
                                   },
                                   {
                                     category: 'Десерти',
                                     price   : 100,
                                     name    : 'Оладки з полуницею',
                                     isNew   : false,
                                     isSale  : false,
                                     weight  : '200 г',
                                     image   : 'images/dessert/6.jpg',
                                     slider  : [
                                       'images/dessert/6.jpg',
                                       'images/dessert/6.jpg',
                                       'images/dessert/6.jpg'
                                     ]
                                   },
                                   {
                                     category: 'Десерти',
                                     price   : 120,
                                     name    : 'Бісквітно-ягідний рулет',
                                     isNew   : false,
                                     isSale  : false,
                                     weight  : '210 г',
                                     image   : 'images/dessert/2.jpg',
                                     slider  : [
                                       'images/dessert/2.jpg',
                                       'images/dessert/2.jpg',
                                       'images/dessert/2.jpg'
                                     ]
                                   },
                                   {
                                     category: 'Десерти',
                                     price   : 160,
                                     name    : 'Запіканка із млинців у білковому суфле',
                                     isNew   : false,
                                     isSale  : false,
                                     weight  : '250 г',
                                     image   : 'images/dessert/1.jpg',
                                     slider  : [
                                       'images/dessert/1.jpg',
                                       'images/dessert/1.jpg',
                                       'images/dessert/1.jpg'
                                     ]
                                   }
                                 ]
    
                               ,
                               options  : [
                                 {category: 'Бургери', name: 'BBQ', value: 'bbq'},
                                 {
                                   category: 'Бургери',
                                   name    : 'Для вегетаріанців',
                                   value   : 'vegetarian'
                                 },
                                 {category: 'Бургери', name: 'Новинки', value: 'new'},
                                 {category: 'Бургери', name: 'З цибулею', value: 'onion'},
                                 {category: 'Бургери', name: 'З беконом', value: 'backon'},
                                 {category: 'Бургери', name: 'Сир', value: 'cheese'},
                                 {category: 'Піца', name: 'З ковбасками', value: 'bbq'},
                                 {category: 'Піца', name: 'Для вегетаріанців', value: 'vegetarian'},
                                 {category: 'Піца', name: 'Новинки', value: 'new'},
                                 {category: 'Піца', name: 'З цибулею', value: 'onion'},
                                 {category: 'Піца', name: 'З беконом', value: 'backon'},
                                 {category: 'Піца', name: 'Чеддер', value: 'cheddar'},
                                 {category: 'Піца', name: 'Гриби', value: 'mashrooms'},
                                 {category: 'Піца', name: 'Моцарела', value: 'mozarella'},
                                 {category: 'Суші / роли', name: 'Роли', value: 'roll'},
                                 {
                                   category: 'Суші / роли',
                                   name    : 'Для вегетаріанців',
                                   value   : 'vegetarian'
                                 },
                                 {category: 'Суші / роли', name: 'Суші', value: 'sushi'},
                                 {category: 'Суші / роли', name: 'Сети', value: 'set'},
                                 {category: 'Десерти', name: 'Ягідні', value: 'berry'},
                                 {category: 'Десерти', name: 'Вершкові', value: 'milk'},
                                 {category: 'Десерти', name: 'Шоколадні', value: 'choko'},
                                 {category: 'Десерти', name: 'Фруктові', value: 'fruits'}
                               ],
                               category : [
                                 {name: 'Бургери', link: 'burger', icon: 'icon-burger'},
                                 {name: 'Піца', link: 'pizza', icon: 'icon-pizza'},
                                 {name: 'Суші / роли', link: 'sushi', icon: 'icon-sushi'},
                                 {name: 'Напої', link: 'dring', icon: 'icon-drink'},
                                 {name: 'Десерти', link: 'desert', icon: 'icon-dessert'}
                               ],
                               banerInfo: [
                                 {
                                   price      : 120,
                                   name       : 'Бургер “Chicago”',
                                   description: 'дві котлети з телятини, подвійний сир чеддер, солений огірок, цибуля ріпчаста, листя салату',
                                   image      : 'images/baners/baner1.jpg',
                                   link       : '/burger/item/0'
                                 },
                                 {
                                   price      : 120,
                                   name       : 'Суші вугор',
                                   description: 'рис, вугор, норі, кунжут, соус унагі',
                                   image      : 'images/baners/baner2.jpg',
                                   link       : '/sushi/item/9'
                                 },
                                 {
                                   price      : 175,
                                   name       : 'Піца “Хантер”',
                                   description: 'ферментоване м\'ясо, моцарела, телятина маринована, пепероні, бекон, мисливські ковбаски, соу спайс',
                                   image      : 'images/baners/baner3.jpg',
                                   link       : '/pizza/item/5'
                                 },
                                 {
                                   price      : 120,
                                   name       : 'Тістечка “Чоко-сет”',
                                   description: 'шоколадний чізкейк з вишнею, крем-карамель, медовик з мигдалем, наполеон, червоний оксамит',
                                   image      : 'images/baners/baner4.jpg',
                                   link       : '/desert/item/15'
                                 },
                                 {
                                   price      : 120,
                                   name       : 'Молочно-полуничний коктейль',
                                   description: 'Молоко, цукор тростинний, полуниця, вершки',
                                   image      : 'images/baners/baner5.jpg',
                                   link       : '/dring/item/11'
                                 }
                               ],
                               addition : [
      
                                 {
                                   category: 'Бургери',
                                   name    : 'огірок маринований',
                                   image   : 'images/additives/cucumber.jpg',
                                   price   : 10,
                                   weight  : '50 г'
                                 },
                                 {
                                   category: 'Бургери',
                                   name    : 'цибуля',
                                   image   : 'images/additives/onion.jpg',
                                   price   : 15,
                                   weight  : '30 г'
                                 },
                                 {
                                   category: 'Бургери',
                                   name    : 'Сир чеддер',
                                   image   : 'images/additives/chedder.jpg',
                                   price   : 35,
                                   weight  : '50 г'
                                 },
                                 {
                                   category: 'Бургери',
                                   name    : 'Томати',
                                   image   : 'images/additives/tomato.jpg',
                                   price   : 30,
                                   weight  : '50 г'
                                 },
                                 {
                                   category: 'Бургери',
                                   name    : 'Куряче м\'ясо',
                                   image   : 'images/additives/chicken.jpg',
                                   price   : 60,
                                   weight  : '50 г'
                                 },
                                 {
                                   category: 'Бургери',
                                   name    : 'Телятина',
                                   image   : 'images/additives/meat.jpg',
                                   price   : 80,
                                   weight  : '50 г'
                                 },
                                 {
                                   category: 'Піца',
                                   name    : 'дорблю',
                                   image   : 'images/additives/dorblue.jpg',
                                   price   : 27,
                                   weight  : '20 г'
                                 },
                                 {
                                   category: 'Піца',
                                   name    : 'рікота',
                                   image   : 'images/additives/ricota.jpg',
                                   price   : 27,
                                   weight  : '20 г'
                                 },
                                 {
                                   category: 'Піца',
                                   name    : 'Соус часниковий',
                                   image   : 'images/additives/white-souse.jpg',
                                   price   : 27,
                                   weight  : '20 г'
                                 },
                                 {
                                   category: 'Піца',
                                   name    : 'Соус кисло-солодкий',
                                   image   : 'images/additives/red-souse.jpg',
                                   price   : 27,
                                   weight  : '20 г'
                                 },
                                 {
                                   category: 'Піца',
                                   name    : 'Куряче м\'ясо',
                                   image   : 'images/additives/chicken.jpg',
                                   price   : 60,
                                   weight  : '50 г'
                                 },
                                 {
                                   category: 'Піца',
                                   name    : 'Телятина',
                                   image   : 'images/additives/meat.jpg',
                                   price   : 80,
                                   weight  : '50 г'
                                 },
                                 {
                                   category: 'Піца',
                                   name    : 'Томати',
                                   image   : 'images/additives/tomato.jpg',
                                   price   : 30,
                                   weight  : '50 г'
                                 },
                                 {
                                   category: 'Піца',
                                   name    : 'Цибуля',
                                   image   : 'images/additives/onion.jpg',
                                   price   : 15,
                                   weight  : '30 г'
                                 },
                                 {
                                   category: 'Десерти',
                                   name    : 'Джем полуничний',
                                   image   : 'images/additives/jam.jpg',
                                   price   : 20,
                                   weight  : '50 г'
                                 },
                                 {
                                   category: 'Десерти',
                                   name    : 'Збиті вершки',
                                   image   : 'images/additives/crema.jpg',
                                   price   : 15,
                                   weight  : '50 г'
                                 },
                                 {
                                   category: 'Десерти',
                                   name    : 'Мед',
                                   image   : 'images/additives/honey.jpg',
                                   price   : 35,
                                   weight  : '50 г'
                                 },
                                 {
                                   category: 'Десерти',
                                   name    : 'Кленовий сироп',
                                   image   : 'images/additives/suryp.jpg',
                                   price   : 30,
                                   weight  : '50 г'
                                 }
                               ],
                               buyMore  : [
                                 {
                                   category: 'Бургери',
                                   list    : [11, 12, 13]
                                 },
                                 {
                                   category: 'Піца',
                                   list    : [11, 12, 13]
                                 },
                                 {
                                   category: 'Суші / роли',
                                   list    : [11, 12, 13]
                                 },
                                 {
                                   category: 'Напої',
                                   list    : [5, 0, 9]
                                 },
                                 {
                                   category: 'Десерти',
                                   list    : [11, 12, 13]
                                 }
    
                               ],
                               busked   : []
                             },
                             mutations: {
                               addToBasked(state, item) {
                                 state.busked.push(item)
                               },
                               delBasked(state, index) {
                                 state.busked.splice(index, 1)
                               },
                               clearBasked(state) {
                                 state.busked = []
                               }
                             },
                             actions  : {},
                             modules  : {}
                           })
