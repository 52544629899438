<template>
  <li class="catalog-item">
    <div class="item-holder">
      <div class="product-label label-new" v-if="item.isNew">
        <span class="label-text">New</span>
      </div>
      <div class="product-label label-sale" v-if="item.isSale">
        <span class="label-text">% Знижка</span>
      </div>

      <div class="value-info">
        <span class="name">Вага:</span>
        <span class="value">{{weight}}</span>
      </div>
      <div class="options">
        <div class="price-box">
          <div class="price-element">₴ {{price}}</div>
        </div>
        <div class="right-options">
          <div class="size-radio-group" v-if="item.size">
            <div class="size-radio" v-for="(size, index) in item.size" :key="index">
              <input :id="'size'+item.id+index" type="radio" :name="'size-radio'+item.id" v-model="sizeSelected" :value="size">
              <label :for="'size'+item.id+index">{{size.name}}</label>
            </div>
          </div>

          <button class="default-btn light-btn quick_cart_trigger " @click.prevent="buy">
            <span class="btn-text">Замовити</span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M6.25 7.08325L6.25 6.66658C6.25 4.59552 7.92893 2.91658 10 2.91658V2.91658C12.0711 2.91658 13.75 4.59551 13.75 6.66658L13.75 7.08325"
                  stroke="white" stroke-linecap="round"/>
              <path d="M12.9165 11.2499V9.58325" stroke="white" stroke-linecap="round"/>
              <path d="M7.0835 11.2499V9.58325" stroke="white" stroke-linecap="round"/>
              <path
                  d="M4.5835 10.6833C4.5835 8.9862 4.5835 8.13767 5.1107 7.61046C5.63791 7.08325 6.48644 7.08325 8.1835 7.08325H11.8168C13.5139 7.08325 14.3624 7.08325 14.8896 7.61046C15.4168 8.13767 15.4168 8.9862 15.4168 10.6833V11.6666C15.4168 13.9324 15.4168 15.0653 14.8362 15.8572C14.6552 16.104 14.4376 16.3216 14.1908 16.5026C13.3988 17.0833 12.2659 17.0833 10.0002 17.0833V17.0833C7.73438 17.0833 6.6015 17.0833 5.80957 16.5026C5.56277 16.3216 5.34512 16.104 5.16416 15.8572C4.5835 15.0653 4.5835 13.9324 4.5835 11.6666V10.6833Z"
                  stroke="white"/>
            </svg>
          </button>
        </div>
      </div>
      <router-link :to="{name: 'Item', params:{ category: selectCategory.link, id: item.id }}" class="image bg"
                   :style="{backgroundImage: 'url(' + item.image + ')'}">
        <div class="item-content">
          <div class="product-name">{{item.name}}</div>
          <p class="caption">{{item.description}}</p>
        </div>
      </router-link>
    </div>
  </li>
</template>
<script>
  export default {
    name    : 'CatalogItem',
    props   : {
      catalog       : {},
      selectCategory: {},
      item          : {}
    },
    data() {
      return {
        order          : {
          sizeSelect    : undefined,
          count         : 1,
          additionSelect: []
        },
        sizeSelectIndex: 0
      }
    },
    computed: {
      basked() {
        return this.$store.state.busked
      },
      weight() {
        return this.sizeSelected ? this.sizeSelected.weight : this.item.weight
      },
      price() {
        return this.item.price + this.size_price
      },
      size_price() {
        return this.item.size ? this.sizeSelected.price : 0
      },
      sizeSelected: {
        get() {
          return this.item.size ? this.order.sizeSelect ? this.order.sizeSelect : this.item.size[0] : undefined
        },
        set(value) {
          this.order.sizeSelect = value
        }
      }
    },
    methods : {
      buy() {
        let idx = -1;
        if (this.sizeSelected) {
          idx = this.basked.findIndex(i => i.id === this.item.id && i.sizeSelect.name === this.sizeSelected.name)
        } else {
          idx = this.basked.findIndex(i => i.id === this.item.id)
        }
        if (idx > -1) {
          // this.basked[idx].count += this.order.count;
        } else {
          this.$store.commit(
            'addToBasked',
            JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item, {sizeSelect: this.sizeSelected})))
          );
        }
        window.$('body').addClass('no-scroll');
        window.$('#quick_cart').addClass('opened');
        window.$('.frame-modal-wrapper-bg').addClass('is-visible');
      }
    }
  }
</script>