<template>
  <div class="overlay-menu">
    <a class="close-menu-btn" href="javascript:void(0);">
      <img src="/images/icons/close.svg">
    </a>
    <div class="overlay-menu-wrapper">
      <div class="menu-list">
        <div class="logo">
          <img src="/images/logo.svg">
        </div>
        <ul>
          <li>
            <router-link :to="{name: 'Category'}">Головна</router-link>
          </li>
          <li>
            <router-link :to="{name: 'About'}">Про нас</router-link>
          </li>
          <li>
            <router-link :to="{name: 'Delivery'}">Доставка та оплата</router-link>
          </li>
          <li>
            <router-link :to="{name: 'Contact'}">Контакти</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="soc-link">
      <a href="https://www.instagram.com/uxlove.studio/"># instagram</a>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'MainMenu'
  }
</script>