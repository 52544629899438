<template>
  <div class="default-wrapper wrapper">
    <h2>Про нас</h2>
    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum officiis nemo provident ab totam expedita iste pariatur eligendi voluptatem sapiente maiores voluptatum, possimus vel incidunt neque suscipit at repellendus veritatis.</p>
  </div>
</template>
<script>
  export default {
    name : 'About',
    data() {
      return {
      }
    },

  }
</script>