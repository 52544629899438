<template>
  <header>
    <div class="header-holder">
      <router-link :to="{name: 'Category'}" class="logo">
        <img src="/images/logo.svg"></router-link>
<!--      <a href="index.html" class="logo">-->
<!--      </a>-->
      <div class="right-header-panel">
        <a href="https://t.me/UXLoveBot?start=invoice_from_demo" class="chat-btn">
          <span class="text">Зв’язок з менеджером</span>
          <img src="/images/icons/chat.svg" alt="Зв’язок з менеджером">
        </a>
        <a href="javascript:void(0);" class="show-menu-btn">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.1665 5.83325H15.8332" stroke="#171717" stroke-linecap="round"/>
            <path d="M4.1665 10H15.8332" stroke="#171717" stroke-linecap="round"/>
            <path d="M4.1665 14.1667H15.8332" stroke="#171717" stroke-linecap="round"/>
          </svg>
        </a>
      </div>
    </div>
  </header>

  <div class="category-panel" id="category_panel">
    <div class="category-panel-holder">
      <div class="panel-name">категорії:</div>
      <ul>
        <li v-for="(cat, index) in categories" :key="index">
          <router-link :to="{name: 'Category', params:{ category: cat.link }}" active-class="active">
            <span class="icons" :class="cat.icon"></span>
            <span class="text">{{cat.name}}</span>
          </router-link>
        </li>

      </ul>
    </div>
  </div>

</template>
<script>
  export default {
    name: 'MainHeader',
    computed:{
      categories() {
        return this.$store.state.category
      }
    },
    mounted(){
      (function ($) {
        'use strict';
        $('.frame-modal-wrapper-bg').on('click', function () {
          $(this).removeClass('is-visible');
          $('.overlay-menu').removeClass('active');
          $('body').removeClass('no-scroll');

          $('#quick_cart').removeClass('opened');
          return false;
        });

        $('.show-menu-btn').on('click', function () {
          $('.overlay-menu').addClass('active');
          $('body').addClass('no-scroll');
          $('.frame-modal-wrapper-bg').addClass('is-visible');
          return false;
        });
        $('.overlay-menu .close-menu-btn').on('click', function () {
          $('.overlay-menu').removeClass('active');
          $('body').removeClass('no-scroll');
          $('.frame-modal-wrapper-bg').removeClass('is-visible');
          return false;
        });
      })(window.$);

      window.$(document).ready(function () {
        window.$('#category_panel').stick_in_parent({offset_top: 0});
      });
    }
  }
</script>