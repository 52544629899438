<template>
  <div class="order-wrapper wrapper">
    <div class="name h2">Оформлення замовлення:</div>
<!--    <form>-->
      <div class="personal-data">
        <h3>1. Персональні дані</h3>
        <div class="panel-bordered">
<!--          <div class="form-group">-->
<!--            <label class="label-custom">-->
<!--              Ім'я<span>*</span>-->
<!--            </label>-->
<!--            <input type="text" class="form-control custom-input" placeholder="Введіть ваше ім'я">-->
<!--          </div>-->

<!--          <div class="form-group">-->
<!--            <label class="label-custom">-->
<!--              Прізвище-->
<!--            </label>-->
<!--            <input type="text" class="form-control custom-input" placeholder="Введіть ваше прізвище">-->
<!--          </div>-->

          <div class="form-group">
            <label class="label-custom">
              Телефон для підтвердження замовлення<span>*</span>
            </label>
            <input type="text" class="form-control custom-input" placeholder="+380971111111" v-model="phone">
<!--            <p class="help-text">Введіть коректний номер</p>-->
          </div>
        </div>
      </div>
      <div class="delivery-data">
        <h3>2. Доставка</h3>
        <div class="delivery-selector">
          <div class="selector-icons">
            <img src="/images/icons/location-pin.svg" alt="location">
          </div>
          <div class="selector-body">
            <div class="selector-name">Ваше місто</div>
            <select v-model="city">
              <option value="Київ">Київ</option>
              <option value="Львів">Львів</option>
              <option value="Харків">Харків</option>
            </select>
            <div class="selector-district">Київська область</div>
          </div>
        </div>
        <label class="label-custom">
          Вулиця, номер будинку<span>*</span>
        </label>
        <div class="form-row row">
          <div class="form-group street">
            <input type="text" class="form-control custom-input " placeholder="Вулиця" v-model="addr1">
          </div>
          <div class="form-group number-house">
            <input type="text" class="form-control custom-input " placeholder="Будинок" v-model="addr2">
          </div>
        </div>
      </div>
      <div class="payment-data">
        <h3>3. Оплата</h3>

        <div class="payment-group">
          <!--paument radio-->
          <div class="option-radio">
            <input id="money" type="radio" name="payment_select" v-model="payType" value="check">
            <label for="money"><img src="/images/icons/wallet.svg" alt="wallet" class="icons">При отриманні</label>
          </div>
          <!--end paument radio-->
          <!--paument radio-->
          <div class="option-radio">
            <input id="credit_card" type="radio" name="payment_select" checked v-model="payType" value="card">
            <label for="credit_card"><img src="/images/icons/credit-card.svg" alt="credit card" class="icons">
              <img src="/images/liqpay.png" alt="liqpay">
            </label>
          </div>
          <!--end paument radio-->
        </div>
      </div>
      <div class="order-info">
        <h3>Ваше замовлення</h3>

        <div class="cart-item " v-for="(order, index) in busked" :key="index">
          <div class="item-holder">
            <div class="item-info">
              <div class="image bg" :style="{backgroundImage: 'url(/'+order.image+')'}"></div>
              <div class="right-side">
                <div class="name">{{order.name}}</div>
                <div class="info-labels">
                  <!--                      <span class="info-label" v-if="order.count">Кількість: <span>{{order.count}}</span></span>-->
                  <span class="info-label" v-if="order.sizeSelect">Розмір: <span>{{order.sizeSelect.name}}</span></span>
                  <span class="info-label" v-if="order.sizeSelect">Вага: <span>{{order.sizeSelect.weight}}</span></span>
                  <span class="info-label" v-else>Вага: <span>{{order.weight}}</span></span>
                </div>
                <div class="additional">
                  <div class="additional-line" v-for="(add, addindex) in order.additionSelect" :key="addindex">
                    <div class="add-name">{{add.name}}:</div>
                    <name class="value">{{add.price}} грн</name>
                  </div>

                </div>
                <div class="subtotal">{{order.count}} x {{itemPrice(order)}} грн</div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="order-total">
        <div class="name">Всього до сплати:</div>
        <div class="value">
          <div class="price-element">{{totalPrice}} грн</div>
        </div>
      </div>
      <div class="order-buttons">
        <button class="default-btn" @click="makeOrder">
          <span class="btn-text">Сплатити</span>
        </button>
        <div class="link-holder">
          <router-link :to="{name: 'Category'}" class="back-link">
            <img src="/images/icons/left.svg" alt="">
            <span class="text">Назад у магазин</span>
          </router-link>
        </div>
      </div>
<!--    </form>-->

  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name    : 'order',
    data() {
      return {
        posting: false,
        phone: '',
        city: 'Київ',
        addr1: '',
        addr2: '',
        payType: 'card'
      }
    },
    computed: {
      busked() {
        return this.$store.state.busked
      },
      totalPrice() {
        return this.busked.reduce((total, value) => total + this.itemPrice(value), 0)
      }
    },

    methods: {
      itemPrice(item) {
        return (item.price + this.size_price(item) + this.addition_price(item)) * item.count
      },
      size_price(item) {
        return item.sizeSelect ? item.sizeSelect.price : 0
      },
      addition_price(item) {
        return item.additionSelect.reduce((c, value) => c + value.price, 0)
      },
      makeOrder() {
        if (this.posting){
          return
        }
        this.posting = true;
        // console.log('make order', this.busked);
        axios.post('/api/order_food', this.busked, {
          headers: {
            initData: window.Telegram ? window.Telegram.WebApp.initData : ''
          }
        }).then(() => {
          this.posting = false;
          this.$router.push({name: "Category"});
          if (window.Telegram) {
            window.Telegram.WebApp.close()
          }

          this.$store.commit('clearBasked');

          // window.$('.frame-modal-wrapper-bg').removeClass('is-visible');
          // window.$('body').removeClass('no-scroll');
          //
          // window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
          // window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');

        });
      }
    }
  }
</script>
