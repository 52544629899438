<template>
  <div class="catalog-wrapper wrapper">
    <div class="swiper main-gallery">
      <div class="swiper-wrapper">

        <div class="swiper-slide" v-for="(baner, index) in baners" :key="index">
          <div class="carousel-item-body">
            <div class="product-label label-with-icon label-important">
              <img src="images/icons/cheaf-hat.svg">
              <span class="label-text">Від шеф-повара</span>
            </div>
            <div class="text">
              <div class="name h2">{{baner.name}}</div>
              <p class="caption">{{baner.description}}</p>
              <span class="price-element">₴ {{baner.price}}</span>

              <a href="javascript:void(0);" class="item-link" @click="$router.push({path: baner.link})">
                Детальніше
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12.5 10L12.8536 9.64645L13.2071 10L12.8536 10.3536L12.5 10ZM7.85355 4.64645L12.8536 9.64645L12.1464 10.3536L7.14645 5.35355L7.85355 4.64645ZM12.8536 10.3536L7.85355 15.3536L7.14645 14.6464L12.1464 9.64645L12.8536 10.3536Z"
                      fill="white"/>
                </svg>
              </a>
            </div>
            <div class="image bg" :style="{backgroundImage: 'url('+baner.image+')'}"></div>
          </div>
        </div>

      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="sorting-panel" v-if="options.length">
      <div class="custom-label">
        Оберіть своє:
      </div>
      <div class="sort-list">
        <div class="sorting-checkbox" v-for="(op, index) in options" :key="index">
          <input :id="op.value" type="checkbox" v-model="optionSelect" :value="op.value">
          <label :for="op.value">{{op.name}}</label>
        </div>
      </div>
    </div>
    <ul class="catalog-grid">

      <CatalogItem v-for="(item,index) in catalog" :key="index" :item="item" :select-category="selectCategory"/>

    </ul>
  </div>
</template>
<script>
  import CatalogItem from './CatalogItem';

  export default {
    name      : 'Category',
    components: {CatalogItem},
    props     : ['category'],
    data() {
      return {
        optionSelect: [],
        sorting     : ''

      }
    },
    computed  : {
      selectCategory() {
        let idx = this.$store.state.category.findIndex(c => c.link === this.category);
        if (idx < 0) {
          idx = 0
        }
        return this.$store.state.category[idx]
      },
      categories() {
        return this.$store.state.category
      },
      options() {
        return this.$store.state.options.filter(o => o.category === this.selectCategory.name)
      },
      baners() {
        return this.$store.state.banerInfo
      },
      filtered() {
        return this.$store.state.items.map((i, index) => {
          i.id = index;
          return i
        }).filter(i => {
          return i.category === this.selectCategory.name;
        }).map(x => {
          x.link = this.selectCategory.link;
          return x
        });
      },
      catalog() {
        if (this.optionSelect.length){
          let result = this.filtered;
          for (let id =0;id<this.optionSelect.length; id++){
            result = result.filter(i => {
              return i.tags?.join(' ').indexOf(this.optionSelect[id]) > -1
            })
          }
          return  result;
        }
        return this.filtered.map(i => i)
      }
    },

    beforeUnmount() {
      window.topGalery?.destroy();
      console.log('clear galery')
    },
    mounted() {
      (function ($) {
        'use strict';

        $('.quick_cart_trigger, #quick_card_sticker a').on('click', function () {
          $('body').addClass('no-scroll');
          $('#quick_cart').addClass('opened');
          $('.frame-modal-wrapper-bg').addClass('is-visible');
          return false;
        });

        $('.frame-modal-wrapper .close-cart').on('click', function () {
          $('.frame-modal-wrapper').removeClass('opened');
          $('body').removeClass('no-scroll');
          $('.frame-modal-wrapper-bg').removeClass('is-visible');
          return false;
        });

        $(function () {
          if ($(window).scrollTop() >= 100) {
            $('.fixed-holder-btn').addClass('stickybottom');
          }
          $(window).scroll(function () {
            if ($(this).scrollTop() >= 100) {
              $('.fixed-holder-btn').addClass('stickybottom');
            } else {
              $('.fixed-holder-btn').removeClass('stickybottom');
            }
          });

        });

        $(function () {
          if ($(window).scrollTop() >= 100) {
            $('#quick_card_sticker').addClass('stickytop');
          }
          $(window).scroll(function () {
            if ($(this).scrollTop() >= 100) {
              $('#quick_card_sticker').addClass('stickytop');
            } else {
              $('#quick_card_sticker').removeClass('stickytop');
            }
          });
        });

        /* ---------------------------------------------- /*
        * value product spinner
        /* ---------------------------------------------- */
        $('.nt-spinner .nt-btn:last-of-type').on('click', function () {
          var btn   = $(this);
          var input = btn.closest('.nt-spinner').find('input');
          if (input.attr('max') == undefined || parseInt(input.val()) < parseInt(input.attr('max'))) {
            input.val(parseInt(input.val(), 10) + 1);
          } else {
            btn.next('disabled', true);
          }
        });
        $('.nt-spinner .nt-btn:first-of-type').on('click', function () {
          var btn   = $(this);
          var input = btn.closest('.nt-spinner').find('input');
          if (input.attr('min') == undefined || parseInt(input.val()) > parseInt(input.attr('min'))) {
            input.val(parseInt(input.val(), 10) - 1);
          } else {
            btn.prev('disabled', true);
          }
        });

        window.scrollTo(0, 0);

      })(window.$);

      window.$(document).ready(function () {
        window.$('a.scrollto').click(function () {
          let elementClick = window.$(this).attr('href');
          let destination  = window.$(elementClick).offset().top;
          window.$('html:not(:animated),body:not(:animated)').animate({scrollTop: destination}, 1100);
          return false;
        });
      });

      window.topGalery = new window.Swiper('.main-gallery', {
        //effect: 'fade',
        slidesPerView: 1,
        loop         : true,
        spaceBetween : 15,
        speed        : 700,
        autoplay     : {
          delay               : 3000,
          disableOnInteraction: false
        },
        pagination   : {
          el            : '.swiper-pagination',
          dynamicBullets: false
        }

      });
    }
  }
</script>